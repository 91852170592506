import request from '@/utils/http'



export function getCode_api(data){
    return request({
    url:`/v4/user/login?code=${data.code}`,
    method:'post',
    // headers:{
    //   "Content-Type":"application/x-www-form-urlencoded"
    // },
    // data
})
}

export function getuser_api(){
  return request({
  url:`/v4/user/user`,
  method:'get',
  // headers:{
  //   "Content-Type":"application/x-www-form-urlencoded"
  // },
  // data
})
}

// 验证码
export function getSmsCode(data){
  let opt = {
        method: 'POST',
        url: `/v4/common/mobileCode`,
        data,
      };
  
  return request(opt);
}

// 判断用户
// 验证码
export function getApplyUser(data){
  let opt = {
        method: 'get',
        url: `/v4/user/member`,
        data,
      };
  
  return request(opt);
}

// 判断购购
export function setjudge(data){
  let opt = {
        method: 'post',
        url: `/v4/user/member`,
        data,
      };
  
  return request(opt);
}


// 获取七牛云凭证
export function getImgToken(params){
  let opt = {
        method: 'GET',
        url: `/v4/common/uploadToken`,
        params,
      };
  
  return request(opt);
}

// 上传图片
export function uploadImg(data){
  let opt = {
        method: 'POST',
        url: `https://up-z2.qiniup.com/`,
        data,
      };
  
  return request(opt);
}

export function uploadImg1(data,url){
  let opt = {
        method: 'POST',
        url: `https://up-z2.qiniup.com/putb64/${url}`,
        data,
      };
  
  return request(opt);
}

// 配置信息
export function configSetting(data){
  let opt = {
        method: 'POST',
        url: `/v4/common/config`,
        data,
      };
  
  return request(opt);
}

// 配置信息
export function orderPay(data){
  let opt = {
        method: 'POST',
        url: `/v4/user/apply`,
        data,
      };
  
  return request(opt);
}

// 修改信息
export function detailsInfo(data,id){
  let opt = {
        method: 'put',
        url: `/v4/user/apply/`+id,
        data,
      };
  
  return request(opt);
}

// 修改信息
export function auditList(data){
  let opt = {
        method: 'get',
        url: `/v4/user/apply`,
        data,
      };
  
  return request(opt);
}

// 修改信息
export function getDetails(id){
  let opt = {
        method: 'get',
        url: `/v4/user/apply/`+id,

      };
  
  return request(opt);
}

// 修改信息
export function getConfig(data){
  let opt = {
        method: 'get',
        url: `/v4/common/jsSdkConfig`,
        params:data
      };
  
  return request(opt);
}

// 修改信息
export function getSetting(){
  let opt = {
        method: 'get',
        url: `/v5/user/shareholderApply`,

      };
  
  return request(opt);
}

// 修改信息
export function shareholderApply_api(data){
  let opt = {
        method: 'post',
        url: `/v5/user/shareholderApply`,
        data
      };
  
  return request(opt);
}

export function detailShareholderApply_api(data,id){
  let opt = {
        method: 'put',
        url: `/v5/user/shareholderApply/${id}`,
        data
      };
  
  return request(opt);
}

// 修改信息
export function getUserInfo_api(){
  let opt = {
        method: 'get',
        url: `/v5/user/activate`,
      };
  
  return request(opt);
}

// 修改信息
export function getShareDetail_api(id){
  let opt = {
        method: 'get',
        url: `/v5/user/shareholderApply/`+id,
      };
  
  return request(opt);
}

// 转让详情
export function putOrder_api(id){
  let opt = {
        method: 'get',
        url: `/v7/user/sharesTrade/`+id,
      };
  
  return request(opt);
}

// 修改信息
export function getSharePay_api(id,data){
  let opt = {
        method: 'put',
        url: `/v5/user/shareholderOnlinePayment/`+id,
        data
      };
  
  return request(opt);
}

// 兑换
export function putSharePay_api(id,data){
  let opt = {
        method: 'put',
        url: `/v6/user/sharesApply/`+id,
        data
      };
  
  return request(opt);
}

// 修改信息
export function getShipments_api(data){
  let opt = {
        method: 'get',
        url: `/v5/user/shipments`,
        data
      };
  
  return request(opt);
}

// 修改信息
export function putActivate_api(data){
  let opt = {
        method: 'post',
        url: `/v5/user/activate`,
        data
      };
  
  return request(opt);
}

export function SellingDeal_api(id){
  let opt = {
        method: 'get',
        url: '/v7/user/sharesSales/'+id,
      };
  
  return request(opt);
}




